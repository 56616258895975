import React from 'react'

function SaveIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="19" height="16" {...props}>
      <path d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z" />
    </svg>
  )
}

export function SaveIconAlt(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5606 3.06065L10.9393 0.43934C10.658 0.15804 10.2765 0 9.87869 0H1.5C0.67156 0 0 0.67156 0 1.5V12.5C0 13.3284 0.67156 14 1.5 14H12.5C13.3284 14 14 13.3284 14 12.5V4.12132C14 3.7235 13.842 3.34197 13.5607 3.06067L13.5606 3.06065ZM7 12C5.89544 12 5 11.1046 5 9.99999C5 8.89543 5.89544 7.99999 7 7.99999C8.10456 7.99999 9 8.89543 9 9.99999C9 11.1046 8.10456 12 7 12ZM10 2.48374V5.62499C10 5.83208 9.83209 5.99999 9.625 5.99999H2.375C2.16791 5.99999 2 5.83208 2 5.62499V2.37499C2 2.1679 2.16791 1.99999 2.375 1.99999H9.51625C9.61572 1.99999 9.71109 2.03949 9.78141 2.10983L9.89016 2.21858C9.9605 2.28889 10 2.38428 10 2.48374Z"
      />
    </svg>
  )
}

export default SaveIcon
