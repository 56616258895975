import { useEffect } from 'react'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { baseServerSideProps } from 'shared/utils/base-server-side-props'
import { CourseNoLectureScreen } from 'modules/course/components/course-info-screens/course-no-lecture-screen'
import CourseLayout from 'modules/course/components/course-layout'
import useCertificateOfCompletion from 'modules/course/hooks/use-certificate-of-completion'
import { useCourseData } from 'modules/course/hooks/use-course-data'
import { useCourseMenu } from 'modules/course/hooks/use-course-menu'
import { useIsUserAdminOrOwnerOfCourse } from 'modules/course/hooks/use-is-user-admin-or-owner-of-course'
import useStepRouter from 'modules/course/hooks/use-step-router'
import { getCoursePageLayout } from 'modules/course/layouts/get-course-page-layout'

const CoursePage = () => {
  const { user } = useUser()
  const { findStep, redirectToStep } = useStepRouter()
  const { courseData } = useCourseData()
  const isUserAdminOrOwnerOfCourse = useIsUserAdminOrOwnerOfCourse()
  const { areAllModulesUnavailable } = useCourseMenu({ courseId: courseData?.id })
  const { isCertificateAvailable, redirectToCertificate } = useCertificateOfCompletion()

  const showNoLectureScreen =
    user && areAllModulesUnavailable && isAuthorizedUser(user) && isUserAdminOrOwnerOfCourse

  useEffect(() => {
    if (user && isAuthorizedUser(user) && !courseData?.accessFrom) {
      if (isCertificateAvailable) {
        redirectToCertificate()
      } else {
        redirectToStep(findStep())
      }
    }
  }, [courseData?.accessFrom, user, isCertificateAvailable, findStep])

  return <CourseLayout>{showNoLectureScreen && <CourseNoLectureScreen />}</CourseLayout>
}

CoursePage.getLayout = getCoursePageLayout

export const getServerSideProps = baseServerSideProps

export default CoursePage
