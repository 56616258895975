import React from 'react'
import { useTranslation } from 'next-i18next'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import { CourseInfoScreenLayout } from './course-info-screen-layout'

export const CourseNoLectureScreen = () => {
  const { t } = useTranslation()
  return (
    <CourseInfoScreenLayout
      className={addPublicCustomClassNamePrefix(`course__info-screen--no-lectures`)}
      text={t('course.no_lectures_message')}
    />
  )
}
